import { dataAccounting } from "../../../../../components/selectAccountingPlan";
import { CSRF, getData, getElement } from "../../../../../helpers";
import { Dt } from "../../../../../plugins/datatable";
import { AccountingplanModal } from "./modals/store";

class AccountingPlanModule extends HTMLElement {
    connectedCallback() {
        this.load();
    }

    async load() {
        // console.log(this);
        const DtElement = getElement("[data-table=dt]", this);
        const BtnNew = getElement("[name=new]", this); // Identificar el botón para crear
        const BtnFilter = getElement("[name=btnFilter]", this);
        let filterAccount = true;

        //Acciones
        BtnNew?.addEventListener("click", () => {
            AccountingplanModal.create(
                getData(BtnNew, "data-bs-target"),
                getData(BtnNew, "data-url"),
                getData(BtnNew, "title")
            );
        });

        BtnFilter?.addEventListener("click", () => {
            if (BtnFilter) {
                if (getData(BtnFilter, "data-filter") == "false") {
                    BtnFilter.setAttribute("data-filter", "true");
                    BtnFilter.textContent = getData(
                        BtnFilter,
                        "data-filter_account"
                    );
                    filterAccount = false;
                } else {
                    BtnFilter.setAttribute("data-filter", "false");
                    BtnFilter.textContent = getData(
                        BtnFilter,
                        "data-all_account"
                    );
                    filterAccount = true;
                }

                localStorage.setItem("filterAccount", filterAccount);

                T.ajax.reload();
            }
        });

        DtElement?.addEventListener("click", (e) => {
            const btnEdit = e.target.closest("[name=btnEditAccountingPlans]");
            if (btnEdit) {
                AccountingplanModal.edit(
                    getData(btnEdit, "data-bs-target"),
                    getData(btnEdit, "data-url"),
                    getData(btnEdit, "title"),
                    getData(btnEdit, "data-data")
                );
                let data = JSON.parse(atob(btnEdit.getAttribute("data-data")));
                dataAccounting.concat["self"] = data.id;
                dataAccounting.accountingplans = Object.values(
                    dataAccounting.concat
                );
            }
        });

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
        };

        configDt.ajax = {
            url: getData("accountingplansUrlDt"),
            type: "POST",
            data: function (data) {
                data.filterAccount = filterAccount;
            },
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };
        // Columnas del AJAX
        configDt.columns = [
            { data: "DT_RowIndex", width: "3%", className: "text-center" },
            { data: "number" },
            { data: "name" },
            { data: "parent" },
            { data: "journal" },
            { data: "group" },
            {
                data: "status",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
            {
                data: "buttons",
                width: "5%",
                className: "text-center",
                orderable: false,
            },
        ];

        const DtAp = new Dt(configDt);
        let T = await DtAp.dataTable(DtElement);
        // End DataTable

        if (localStorage.getItem("filterAccount")) {
            if (localStorage.getItem("filterAccount") == "false") {
                BtnFilter.setAttribute("data-filter", "true");
                BtnFilter.textContent = getData(
                    BtnFilter,
                    "data-filter_account"
                );
                filterAccount = false;
            } else {
                BtnFilter.setAttribute("data-filter", "false");
                BtnFilter.textContent = getData(BtnFilter, "data-all_account");
                filterAccount = true;
            }

            T.ajax.reload();
        }
    }
}

window.customElements.define("accountingplans-module", AccountingPlanModule, {
    extends: "section",
});
