import {
    CSRF,
    DateFormat,
    getData,
    getDate,
    getElement,
    isDate,
    resetSelect2Ajax,
} from "../../../helpers";
import { Dt } from "../../../plugins/datatable";

class JournalModule extends HTMLElement {
    async connectedCallback() {
        //console.log(this);
        const DtElement = getElement("[data-table=dt]", this);
        const BtnSearch = getElement("[name=search]", this);
        const BtnReset = getElement("[name=reset]", this);
        const Form = getElement("[name=filter]", this);

        let filter = {
            startDate: null,
            endDate: null,
            project: null,
            description: null,
            amount: null,
            entry: null,
        };

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            searching: false,
            pageLength: 50,
            ordering: false,
        };

        configDt.ajax = {
            url: getData("accountingJournalUrlDt"),
            type: "POST",
            data: function (data) {
                data.filter = filter;
            },
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };

        // Columnas del AJAX
        configDt.columns = [
            { data: "entry", className: "text-center" },
            {
                data: "date",
                className: "text-center",
                render: (data, type, row, meta) => {
                    if (isDate(data)) {
                        let date = getDate(data);
                        return date.format(DateFormat);
                    } else {
                        return data;
                    }
                },
            },
            { data: "account" },
            { data: "description" },
            { data: "project" },
            { data: "debit", width: "7%", className: "text-end" },
            { data: "credit", width: "7%", className: "text-end" },
        ];

        const DtAp = new Dt(configDt);
        let T = await DtAp.dataTable(DtElement);

        // End DataTable

        //Acciones
        BtnSearch?.addEventListener("click", () => {
            let dataForm = new FormData(Form);
            filter = Array.from(dataForm).reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

            T.ajax.reload();
        });

        BtnReset?.addEventListener("click", (e) => {
            Form.elements.description.value = "";
            Form.elements.amount.value = "";
            Form.elements.entry.value = "";
            resetSelect2Ajax(getElement("[name=project]", this));
            getElement("[name=startDate]", this).value = "";
            getElement("[name=endDate]", this).value = "";

            filter = {
                startDate: null,
                endDate: null,
                project: null,
                description: null,
                amount: null,
                entry: null,
            };

            T.ajax.reload();
        });
    }
}

window.customElements.define("journal-module", JournalModule, {
    extends: "section",
});
